import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/index.js";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import VueLazyload from 'vue-lazyload'

Vue.use(PrimeVue);
Vue.use(VueLazyload)

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import Carousel from "primevue/carousel";

Vue.component("Carousel", Carousel);

Vue.config.productionTip = false;

Vue.use(ElementUI);
export const eventBus = new Vue({
  methods: {
    dateChanged(newDate) {
      console.log("This is reading a change", newDate);
      this.$emit("dateChanged", newDate);
    },
    emitSearch(index) {
      this.$emit("emitSearch", index);
    },
  },
});

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDvoj8oDkm22W2hukBIysFlMDTv96ecxcc",
    libraries: "places",
  },
  installComponents: true,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
