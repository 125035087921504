<template>
  <div class="footer">
    <footer>
      <el-row class="info">
        <el-col
          class="info__col div_logo"
          :xs="24"
          :sm="8"
          :md="8"
          :lg="8"
          :xl="8"
          ><div class="info__col__div">
            <img
              class="info__col__div__img"
              v-lazy="'https://storage.googleapis.com/imageneswebsite/logos/Sello_blanco_rojo.png'"
            /></div
        ></el-col>
        <el-col class="info__col" :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <el-row class="info__col__row1">
            <el-col
              class="info__col"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <div class="info__col__div">
                <div class="info__col__div__container">
                  <h1>CONTÁCTANOS</h1>
                  <div class="info__col__div__container__logos">
                    <img
                      @click="openLink('facebook')"
                      class="info__col__div__container__logos__img"
                      v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Facebook.png'"
                    />
                    <img
                      @click="openLink('instagram')"
                      class="info__col__div__container__logos__img"
                      v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Instagram.png'"
                    />
                    <img
                      @click="openLink('twiter')"
                      class="info__col__div__container__logos__img"
                      v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Twiter.png'"
                    />
                    <img
                      @click="openLink('whatsapp')"
                      class="info__col__div__container__logos__img"
                      v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Whatsapp.png'"
                    />
                    <img
                      @click="openLink('linkedin')"
                      class="info__col__div__container__logos__img"
                      v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Linkedin.png'"
                    />
                  </div>
                  <a href="https://mail.google.com/mail/u/0/?hl=en&view=cm&tf=1&fs=1&to=nacho@lanacionaldelicores.com">nacho@lanacionaldelicores.com</a>
                </div>
              </div>
            </el-col>

            <el-col
              class="info__col"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <div class="info__col__div">
                <div class="info__col__div__container2">
                  <h1>CALL CENTER</h1>
                  <p>432 56 10 ext 1</p>
                  <p>321 507 57 95 - 321 506 28 24</p>
                  <p>321 506 28 38 - 315 605 06 98</p>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row class="info__col__row2">
            <el-col
              class="info__col"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <div class="leyes_div">
                <p class="leyes">
                  El exceso de alcohol es perjudicial para la salud. Ley 30 de
                  1986. Prohíbase el expendio de bebidas embriagantes a menores
                  de edad y mujeres embarazadas. Ley 124 de 1994.
                </p>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="proveedores">
        <el-col
          class="proveedores__col"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          ><div class="proveedores__col__div">
            <Carousel
              :value="proveedores"
              :numVisible="8"
              :numScroll="1"
              :responsiveOptions="responsiveOptions"
              class="custom-carousel"
              :circular="true"
              :autoplayInterval="2000"
            >
              <template #item="slotProps">
                <div class="p-mb-3">
                  <img
                    class="proveedores__col__div__img"
                    v-lazy="
                      'https://storage.googleapis.com/imageneswebsite/proveedores/' +
                        slotProps.data.nombre +
                        '.png'
                    "
                  />
                </div>
              </template>
            </Carousel></div
        ></el-col>
      </el-row>
    </footer>
  </div>
</template>
<script>
import axios from "../axios/index.js";
export default {
  data() {
    return {
      proveedores: null,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      if (link == "facebook") {
        window.open("https://www.facebook.com/LaNacionalDeLicores/", "_blank");
      } else if (link == "instagram") {
        window.open("https://www.instagram.com/lanacionaldelicores/", "_blank");
      } else if (link == "twiter") {
        window.open("https://twitter.com/NacionalLicores", "_blank");
      } else if (link == "whatsapp") {
        window.open(
          "https://api.whatsapp.com/send?phone=573156050698&text=%C2%A1Hola%21%20Gracias%20por%20comunicarte%20con%20La%20Nacional%20de%20Licores.%20%C2%BFEn%20que%20podemos%20colaborarte%3F%20%F0%9F%8D%BA%F0%9F%8D%BA",
          "_blank"
        );
      } else if (link == "linkedin") {
        window.open(
          "https://co.linkedin.com/company/la-nacional-de-licores",
          "_blank"
        );
      }
    },
  },
  async mounted() {
    await axios.get("/proveedores").then((response) => {
      if (response.status == 200) {
        // console.log(response.data);
        this.proveedores = response.data;
      }
    });
  },
};
</script>
<style scoped>
.custom-carousel {
  height: 100%;
}

.custom-carousel >>> .p-carousel-content {
  height: 100%;
}

.custom-carousel >>> .p-carousel-content .p-carousel-container {
  height: 100%;
}

.custom-carousel >>> .p-carousel-items-container {
  height: 100%;
}

a{
  color: white;
}

h1,
p {
  margin: 0;
}

.leyes_div {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.leyes {
  text-transform: uppercase;
  font-size: 1vw;
  padding-bottom: 2%;
}

h1 {
  font-size: 3.5vw;
}

.footer {
  height: 300px;
  width: 100%;
}

footer {
  height: 100%;
}

.info {
  height: 75%;
}

.proveedores {
  height: 25%;
}

.proveedores__col,
.info__col {
  color: white;
  height: 100%;
}

.info__col {
  background-color: #1e143c;
}

.proveedores__col {
  background-color: rgb(17, 17, 31);
}

.info__col__row1 {
  height: 80%;
}

.info__col__row2 {
  height: 20%;
}

.info__col__div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info__col__div__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info__col__div__container h1 {
  margin: 0;
}

.info__col__div__container p {
  margin: 0;
}

.info__col__div__img {
  float: left;
  max-width: 98%;
  height: auto;
  width: 80%;
  object-fit: contain;
}

.info__col__div__container__logos {
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.info__col__div__container__logos__img {
  float: left;
  max-width: 98%;
  height: 100%;
  width: 12%;
  object-fit: contain;
  cursor: pointer;
}

.info__col__div__container__logos__img:hover {
  opacity: 0.6;
}

.info__col__div__container2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.info__col__div__container2 h1 {
  margin-bottom: 4%;
}

.proveedores__col__div {
  height: 100%;
}

.proveedores__col__div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.proveedores__col__div >>> .p-carousel-indicators {
  display: none;
  padding: 0 !important;
}

.p-mb-3 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.proveedores__col__div >>> .p-link {
  display: none;
}

.proveedores__col__div__img {
  float: left;
  max-width: 100%;
  width: 70%;
  height: 100%;
  object-fit: contain;
}

@media all and (min-width: 1024px) {
  .footer {
    height: 500px;
    width: 100%;
  }

  .leyes > p {
    padding-bottom: 2%;
  }

  h1 {
    font-size: 2vw;
  }

  p, a {
    font-size: 1.5vw;
  }

  .info__col__div__img {
    width: 40%;
  }

  .info__col__div__container2 {
    text-align: left;
  }

  .info__col__div__container__logos__img {
    width: 14%;
  }

  .proveedores__col__div__img {
    float: left;
    max-width: 100%;
    width: 70%;
    height: 100%;
    object-fit: contain;
  }
}

@media all and (min-width: 2000px) {
  .footer {
    height: 600px;
    width: 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 1024px) {
  .footer {
    height: 300px;
    width: 100%;
  }

  h1 {
    font-size: 2.5vw;
  }

  p,a  {
    font-size: 1.3vw;
  }

  .info__col__div__img {
    width: 50%;
  }

  .info__col__div__container__logos__img {
    width: 15%;
  }

  .proveedores__col__div__img {
    float: left;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media all and (max-width: 600px) {
  .footer {
    height: 1000px;
  }

  h1 {
    font-size: 7vw;
  }

  p,a  {
    font-size: 5.5vw;
  }

  .leyes {
    text-transform: uppercase;
    font-size: 3vw;
  }

  .info {
    height: 85%;
  }

  .info__col {
    height: 60%;
  }

  .div_logo {
    height: 40%;
  }

  .vacio {
    height: 1%;
  }

  .proveedores {
    height: 15%;
  }

  .info__col__row1 {
    height: 70%;
  }

  .info__col__row2 {
    height: 30%;
  }

  .info__col__div__container {
    align-items: center;
  }

  .info__col__div__container__logos {
    justify-content: center;
    width: 100%;
  }
  .info__col__div__container__logos img {
    width: 15%;
  }

  .info__col__div__img {
    margin-top: 5%;
    width: 55%;
  }

  .proveedores__col__div {
    flex-direction: column;
  }

  .proveedores__col__div__img {
    float: left;
    height: 100%;
    width: 100%;
  }
}
</style>
