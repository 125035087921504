<template>
  <div>
    <h1>¿QUIÉNES SOMOS?</h1>
    <p>
      {{ textoQuienesSomos }}
    </p>

    <Tarjeta
      :derecha="true"
      titulo="NUESTRA MISIÓN"
      :texto="mision"
      imagen="https://storage.googleapis.com/imageneswebsite/recursos/mision.jpg"
    />

    <Tarjeta
      :derecha="false"
      titulo="NUESTRA VISIÓN"
      :texto="vision"
      imagen="https://storage.googleapis.com/imageneswebsite/recursos/vision.jpg"
    />

    <h1 style="margin:3% 0;">NUESTRO COMPROMISO SOCIAL</h1>

    <el-row>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="imgMain">
          <img
            class="image1"
            v-lazy="'https://storage.googleapis.com/imageneswebsite/compromisoSocial/CiudaddeDios_2.jpg'"
          /></div
      ></el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="container_carousel">
          <el-carousel :interval="4000" arrow="always" :height="alturaCarousel">
            <el-carousel-item
              style="background-color: rgb(193,212,223
            );"
              v-for="item in 5"
              v-if="item != 2"
              :key="item"
            >
              <img
                class="image1"
                v-lazy="
                  'https://storage.googleapis.com/imageneswebsite/compromisoSocial/CiudaddeDios_' +
                    item +
                    '.jpg'
                "
              />
            </el-carousel-item>
          </el-carousel></div
      ></el-col>
    </el-row>

    <el-row>
      <div class="comprometidos">
        <p>
          Estamos comprometidos con el bienestar de nuestra comunidad y el
          desarrollo de acciones que fortalezcan la educación de nuestras
          generaciones. Acompáñanos a apoyar La Fundación ciudad de Dios ...
        </p>
        <el-link
          type="success"
          href="https://www.facebook.com/Fundaci%C3%B3n-Ciudad-de-Dios-101899595102665/photos/?ref=page_internal"
          target="_blank"
          >Visita su página
        </el-link>
      </div>
    </el-row>

    <!-- <Tarjeta
      :derecha="true"
      texto="Estamos comprometidos con el bienestar de nuestra comunidad y el desarrollo de acciones que fortalezcan la educación de nuestras generaciones. Acompáñanos a apoyar La Fundación ciudad de Dios ..."
      imagen="https://storage.googleapis.com/imageneswebsite/compromisoSocial/compromisoSocial.png"
      link="https://www.facebook.com/Fundaci%C3%B3n-Ciudad-de-Dios-101899595102665/photos/?ref=page_internal"
    /> -->
    <!-- <h1>{{ redirect }}</h1> -->
  </div>
</template>
<script>
import Tarjeta from "../components/Tarjeta.vue";
import axios from "../axios/index.js";
export default {
  props: {
    redirect: Boolean,
  },
  components: {
    Tarjeta,
  },
  data() {
    return {
      textoQuienesSomos: "",
      alturaCarousel: 30 + "vw",
      mision: "",
      vision: "",
    };
  },
  methods: {
    scrollToElement() {
      const el = this.$el.getElementsByClassName("imgMain")[0];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.alturaCarousel = 50 + "vw";
    } else if (width < 1000) {
      this.alturaCarousel = 30 + "vw";
    }

    axios.get("/texto/quienesSomos").then((response) => {
      if (response.status == 200) {
        this.textoQuienesSomos = response.data.texto;
      }
    });

    axios.get("/texto/mision").then((response) => {
      if (response.status == 200) {
        this.mision = response.data.texto;
      }
    });

    axios.get("/texto/vision").then((response) => {
      if (response.status == 200) {
        this.vision = response.data.texto;
      }
    });

    if (this.redirect == true) {
      this.scrollToElement();
    }
  },
};
</script>
<style scoped>
.titulo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.comprometidos {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #f6f7f5;
}

.comprometidos p {
  margin: 0;
  padding: 2% 5%;
  text-align: center;
}

.comprometidos p,
a {
  font-size: 1.5vw;
}

.comprometidos a {
  padding: 0 0 2% 0;
}

.imgMain {
  height: 30vw;
}

.imgMain img {
  object-fit: fill;
}

.image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  margin: 2% 0;
  text-align: center;
  color: #1e143c;
  font-size: 2.5vw;
}

p {
  margin: 1% 5%;
  text-align: center;
  color: #1e143c;
  font-size: 1.5vw;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
}

@media all and (max-width: 600px) {
  h1 {
    font-size: 8vw;
    margin-top: 6%;
  }

  .imgMain {
    height: 50vw;
  }

  .imgMain img {
    object-fit: contain;
    background-color: rgb(193, 217, 228);
  }

  .el-col {
    height: 50%;
  }

  p {
    font-size: 4.5vw;
  }

  .comprometidos p, a {
   font-size: 4.5vw; 
  }


}
</style>
