<template>
  <div class="terminos">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif"
          >TÉRMINOS Y CONDICIONES DE USO PLATAFORMA DOMICILIOS LA NACIONAL DE
          LICORES</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Al acceder a esta plataforma, debe suministrar sus datos de contacto,
          tanto personales como públicos, y al comprar en esta aplicación móvil
          usted se compromete a leer, informarse y cumplir los términos y
          condiciones de uso, además se obliga a respetar las políticas de
          privacidad de conformidad con la legislación colombiana sobre el tema,
          pues el contenido, productos y ofertas que usted encuentra en esta
          aplicación, aplican únicamente para Colombia, en el territorio
          especificado en la aplicación.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif">Aceptación</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Al utilizar la plataforma tecnológica, los usuarios aceptan y
          reconocen que han leído, entendido y aceptado estas Condiciones de uso
          y se acogen a lo que se indica en las mismas.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Por el hecho de ingresar a la plataforma, los usuarios otorgan a LA
          NACIONAL:</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• El derecho de modificar en cualquier momento y por cualquier razón
          sin previo aviso estas Condiciones de uso, incluyendo la Política de
          privacidad de la información.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• El derecho de restringir el registro y el uso de la aplicación
          móvil a cualquier persona, en cualquier momento por razones de uso
          inadecuado de la misma.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• El derecho de abstenerse de despachar cualquier pedido solicitado,
          incluso cuando el mismo se encuentre pago, por cualquier irregularidad
          que se identifique ya sea en la información, pago y/o producto, sin
          perjuicio de la devolución del dinero a que haya lugar.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Generar devolución de dinero descontadas las comisiones de los
          diferentes actores y así mismo los impuestos a que haya lugar, siempre
          que la devolución sea por causa de error del usuario.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Generar devolución de dinero sin realizar ningún descuento, cuando
          el producto sea defectuoso, o cuando el usuario haya iniciado un
          trámite de fraude sobre su medio de pago, ante su entidad financiera y
          esta así lo reporte a la pasarela de pago y/o administrador de la
          aplicación.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Los usuarios se abstendrán de usar la aplicación móvil de LA NACIONAL
          de la siguiente manera o con los siguientes objetivos:</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con fines ilícitos.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con fines lesivos de los derechos e intereses de terceros.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con fines que puedan dañar, inutilizar, sobrecargar, deteriorar o
          impedir la normal utilización de los servicios, los equipos
          informáticos, los documentos, los archivos y cualquier contenido
          almacenado en los servidores de LA NACIONAL o en aquellos servidores a
          los que los recursos de LA NACIONAL están enlazados.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con el fin de acosar y/o amenazar a los integrantes del equipo de
          LA NACIONAL o a terceros.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con el fin de obtener o divulgar información privada de
          terceros.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con el fin de promocionar personas, sitios web, empresas, redes de
          blogs, productos, campañas comerciales, campañas políticas o
          ideológicas, campañas que promuevan el voto masivo, la participación
          en manifestaciones y demás.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >• Con el fin de provocar la molestia injustificada a los demás
          usuarios.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL declara que, en su calidad de distribuidor, da
          cumplimiento en lo que le concierne a la Ley 1480 de 2011, así como el
          Decreto 587 de 2016 y demás normas reglamentarias, que las modifiquen,
          supriman o adicionen.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL da cumplimiento a la ley 124 de 1994, y por ende solicita
          a los usuarios indicación sobre la mayoría de edad a fin de continuar
          con el procedimiento de pedido, sin perjuicio de ello, si LA NACIONAL
          por algún medio detecta la posibilidad de fraude o expendio de bebidas
          embriagantes a menores de edad, será causal de rechazo del
          pedido.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Horario de atención</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL, por medio de su plataforma de pedidos de bebidas
          embriagantes y otros productos comestibles, informará a los usuarios
          su disponibilidad de atención y cobertura, los usuarios al entrar a la
          aplicación aceptan dichas limitaciones de horario y lugar de
          atención.</span
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
/* Style Definitions */
.terminos {
  width: 100%;
}

.WordSection1 {
  width: 80%;
  margin: auto;
  padding: 2% 0;
}

p {
  font-size: 1vw !important;
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
  line-height: 115%;
  font-size: 11pt;
  font-family: sans-serif;
}
a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}
.MsoChpDefault {
  font-size: 11pt;
  font-family: sans-serif;
}
.MsoPapDefault {
  margin-bottom: 10pt;
  line-height: 115%;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 70.85pt 85.05pt 70.85pt 85.05pt;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}

@media all and (max-width: 1024px) and (min-width: 600px) {
  p {
    font-size: 2vw !important;
  }
}

@media all and (max-width: 600px) {
  .WordSection1 {
    width: 80%;
    margin: 5% auto;
  }
  p {
    font-size: 3vw !important;
  }
}
</style>
