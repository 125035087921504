import Vue from "vue";
import vueRouter from "vue-router";
import Home from "../views/Home.vue";
import QuienesSomos from "../views/QuienesSomos.vue";
import PreguntasFrecuentes from "../views/PreguntasFrecuentes.vue";
import TeVisitamos from "../views/TeVisitamos.vue";
import TrabajaNosotros from "../views/TrabajaNosotros.vue";
import Terminos from "../views/Terminos.vue";
import TerminosCondiciones from "../views/TerminosCondiciones.vue";
import PoliticaPrivacidad from "../views/PoliticaPrivacidad.vue";

Vue.use(vueRouter);
const routes = [
  { path: "/", component: Home },
  {
    path: "/quienesSomos",
    name: "quienesSomos",
    component: QuienesSomos,
    props: true,
  },
  { path: "/preguntasFrecuentes", component: PreguntasFrecuentes },
  { path: "/teVisitamos", component: TeVisitamos },
  { path: "/trabajaConNosotros", component: TrabajaNosotros },
  { path: "/terminos", component: Terminos },
  { path: "/terminosCondiciones", component: TerminosCondiciones },
  { path: "/politicaPrivacidad", component: PoliticaPrivacidad },
  { path: "*", redirect: "/" },
];

export default new vueRouter({ mode: "history", routes });
