<template>
  <div class="header">
    <nav :class="{ navHome: isHome }">
      <ul class="menu">
        <li @click="NavTo($event, '/')" class="logo">
          <div class="imgs">
            <img
              class="imgs_logo"
              v-lazy="'https://storage.googleapis.com/imageneswebsite/logos/Logo_logo_rojo.png'"
            />
            <img
              class="imgs_text"
              v-lazy="getImgNameUrl(isHome)"
            />
          </div>
        </li>

        <li
          @click="NavTo('/quienesSomos')"
          :class="[{ active: isActive }, 'item']"
        >
          <a :class="{ aHome: isHome, prueba: selected == 1 }"
            >¿QUIÉNES SOMOS?</a
          >
        </li>
        <li
          @click="NavTo('/teVisitamos')"
          :class="[{ active: isActive }, 'item']"
        >
          <a :class="{ aHome: isHome, prueba: selected == 2 }"
            >¿TIENES UN NEGOCIO?</a
          >
        </li>
        <li
          @click="NavTo('/preguntasFrecuentes')"
          :class="[{ active: isActive }, 'item']"
        >
          <a :class="{ aHome: isHome, prueba: selected == 3 }"
            >PREGUNTAS FRECUENTES</a
          >
        </li>
        <li
          @click="NavTo('/trabajaConNosotros')"
          :class="[{ active: isActive }, 'item']"
        >
          <a :class="{ aHome: isHome, prueba: selected == 4 }"
            >TRABAJA CON NOSOTROS</a
          >
        </li>
        <li @click="NavTo('/terminos')" :class="[{ active: isActive }, 'item']">
          <a :class="{ aHome: isHome, prueba: selected == 5 }"
            >TÉRMINOS Y CONDICIONES</a
          >
        </li>

        <li class="toggle" @click="button">
          <a :class="{ aHome: isHome }"
            ><i
              ref="i"
              :class="[{ 'fas fa-times': isActive }, 'fas fa-bars']"
            ></i
          ></a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
// import axios from "../../axios";
export default {
  data() {
    return {
      url:
        "https://storage.googleapis.com/imageneswebsite/logos/Logo_nombre_azul.png",
      activeIndex: "1",
      isActive: false,
      isHome: true,
      selected: undefined,
      ruta: "/",
    };
  },
  methods: {
    NavTo(route) {
      this.$router.push(`${route}`).catch(() => {});
      this.isActive = false;
      this.getRoutePath();
    },
    button() {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },
    getRoutePath() {
      this.ruta = this.$router.history.current.path;
      if (this.ruta == "/") {
        this.isHome = true;
      } else {
        this.isHome = false;
        this.colorNav();
      }
    },
    getImgNameUrl(isHome) {
      if (isHome == true) {
        return "https://storage.googleapis.com/imageneswebsite/logos/Logo_nombre_azul.png";
      } else {
        return "https://storage.googleapis.com/imageneswebsite/logos/Logo_nombre_blanco.png";
      }
    },
    colorNav() {
      //console.log(this.ruta);
      switch (this.ruta) {
        case "/quienesSomos":
          this.selected = 1;
          break;
        case "/teVisitamos":
          this.selected = 2;
          break;
        case "/preguntasFrecuentes":
          this.selected = 3;
          break;
        case "/trabajaConNosotros":
          this.selected = 4;
          break;
        case "/terminos":
          this.selected = 5;
          break;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // react to route changes...
    // don't forget to call next()
    console.log("algo");
    next();
    console.log("algo");
  },
  created() {
    this.colorNav();
    this.getRoutePath();
  },
  mounted() {
    this.getRoutePath();
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
nav {
  background: #1e143c;
  padding: 5px 20px;
}

.item {
  float: right;
}

.imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navHome {
  background-color: white;
}
ul {
  list-style-type: none;
}
a {
  font-weight: bolder;
  color: white;
  text-decoration: none;
}

.prueba {
  color: rgb(255, 62, 52);
}

.aHome {
  color: #1e143c;
}

a:hover {
  text-decoration: underline;
}

.logo {
  margin-right: 7%;
}

.logo a:hover {
  text-decoration: none;
}

.imgs_logo {
  margin: 0 10%;
  max-width: 98%;
  height: auto;
  width: 20vw;
  object-fit: contain;
}

.imgs_text {
  max-width: 98%;
  height: auto;
  width: 30vw;
  object-fit: contain;
}
.menu li {
  font-size: 4.5vw;
  padding: 15px 5px;
  white-space: nowrap;
  cursor: pointer;
}
.logo a,
.toggle a {
  font-size: 20px;
}
.button.secondary {
  border-bottom: 1px #444 solid;
}

/* Mobile menu */
.menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.toggle {
  order: 1;
}
.item.button {
  order: 2;
}
.item {
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
}
.item.active {
  display: block;
}

@media all and (min-width: 425px) and (max-width: 1000px) {
  .imgs_logo {
    width: 14vw;
  }

  .imgs_text {
    width: 20vw;
  }
  .menu {
    justify-content: center;
  }
  .logo a,
  .toggle a {
    font-size: 30px;
  }
  .logo {
    flex: 1;
  }
  .toggle {
    flex: 1;
    text-align: right;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }
  .toggle {
    order: 2;
  }
  .button.secondary {
    border: 0;
  }
  .button a {
    padding: 7.5px 15px;
    background: teal;
    border: 1px #006d6d solid;
  }
  .button.secondary a {
    background: transparent;
  }
  .button a:hover {
    text-decoration: none;
  }
  .button:not(.secondary) a:hover {
    background: #006d6d;
    border-color: #005959;
  }
  .button.secondary a:hover {
    color: #ddd;
  }
}

@media all and (min-width: 1024px) {
  .menu {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .imgs_logo {
    width: 4.5vw;
  }

  .imgs_text {
    width: 7vw;
  }

  .item {
    display: block;
    width: auto;
    align-items: center;
  }
  .toggle {
    display: none;
  }
  .logo {
    order: 0;
  }
  .item {
    order: 1;
  }
  .button {
    order: 2;
  }
  .menu li {
    font-size: 1.3vw;
    padding: 15px 10px;
  }
  .menu li.button {
    padding-right: 0;
  }
}
</style>
