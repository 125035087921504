<template>
  <div class="terminos">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif"
          >POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL DE LICORES usará la ubicación brindada por el dispositivo
          del usuario con el fin de facilitar el registro de las direcciones en
          la plataforma. Adicionalmente se hará uso de la ubicación en el
          momento que se entregue la orden del usuario al OPERADOR LOGISTICO,
          con el fin de poder hacer entrega de la misma. Salvo que el OPERADOR
          LOGISTICO puede ser un servicio tercerizado contratado por LA NACIONAL
          DE LICORES. En ninguna ocasión esto se usará para fines diferentes a
          los mencionados anteriormente, en caso de que el usuario desee
          desactivar el permiso de ubicación está en su derecho de realizarlo.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif">
          LA NACIONAL DE LICORES garantiza la confidencialidad y seguridad de la
          información ofrecida por el usuario, por lo que no hace seguimiento a
          través de otras aplicaciones ni provee la información necesaria para
          el mismo. LA NACIONAL DE LICORES no hace Cross tracking de los
          usuarios de su aplicación “La Nacho”  Los datos de pago brindados por
          el usuario tales como números de TARJETA DE CREDITO (“TC”) y datos
          personales de la misma no es guardada en ninguna de las bases de datos
          propiedad de LA NACIONAL DE LICORES, la misma es tercerizada a través
          de un token entregado por la pasarela de pagos EPAYCO quien cuenta con
          los certificados de seguridad requeridos.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL dando cumplimiento a los artículos 15 y 20 de la
          Constitución Política Nacional, a la Ley Estatutaria 1581 de 2012, sus
          Decretos Reglamentarios y el desarrollo jurisprudencial dado por la
          Corte Constitucional, garantiza de forma integral la protección y el
          ejercicio del derecho fundamental de Habeas Data respecto a todos los
          titulares de datos personales con los cuales tenga relación sea como
          responsable o sea como encargada de su tratamiento, según
          corresponda.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif">Autorización</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Al entregar mis datos personales solicitados para el uso de la
          aplicación móvil de LA NACIONAL en calidad de usuario, suscriptor,
          tercero o cualquier otra, declaro:</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Que siendo de carácter facultativo dar respuesta a los mismos de
          manera libre, expresa, inequívoca e informada, AUTORIZO a LA NACIONAL,
          para que realice el Tratamiento de mis datos personales y mediante el
          mismo obtenga, compile, intercambie, envíe, adquiera, divulgue, mis
          datos personales contenidos o no en ficheros, archivos, bases de datos
          o medios semejantes desde la captura o recolección directa o indirecta
          en otras bases de datos públicas o privadas, su almacenamiento,
          transferencia, actualización, uso o empleo, circulación y supresión,
          incluyendo datos biométricos; tales como huellas dactilares, registro
          de imagen y de voz; y, demás datos sensibles de conformidad con la
          Ley, con la finalidad de identificarme, validar la información,
          realizar actividades comerciales, encuestas de servicio, envío masivos
          de mensajes (SMS), correos electrónicos con promociones, establecer
          patrones de consumo, análisis estadísticos, probabilísticos,
          elaboración de perfiles de consumo y establecer mis datos de contacto
          para ubicación y notificaciones, tanto presentes como futuras a través
          del Tratamiento de los mismos.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Que tengo derecho a conocer, actualizar y rectificar los datos
          personales proporcionados, solicitar prueba de esta autorización,
          solicitar información sobre el uso que se le ha dado a mis datos
          personales, presentar quejas ante la Superintendencia de Industria y
          Comercio por el uso indebido de mis datos personales, revocar esta
          autorización o solicitar la supresión de los datos personales
          suministrados y a acceder de forma gratuita a los mismos.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL, garantiza la confidencialidad, libertad, seguridad,
          veracidad, transparencia, acceso y circulación restringida de mis
          datos y se reserva el derecho de modificar su Política de Tratamiento
          de Datos Personales en cualquier momento, cambio que será notificado
          en la misma aplicación.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >En señal de aceptación de lo anterior, consiento y autorizo el
          Tratamiento de mis datos personales conforme a lo previsto en la
          presente autorización, mediante actos inequívocos de suministro de mi
          información y accediendo para consulta, uso, cotización, realización
          de pedidos de bebidas embriagantes y otros productos comestibles, así
          como otras acciones a la aplicación móvil de LA NACIONAL.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL, sociedad comercial con domicilio principal en Bogotá
          D.C., Colombia, es RESPONSABLE del Tratamiento de los datos
          personales, de conformidad con la Ley Estatutaria 1581 de 2012. En su
          condición de Responsable, los datos personales que LA NACIONAL obtenga
          por parte del Titular de la Información con ocasión de negocios
          jurídicos y asuntos comerciales entre LA NACIONAL y el Titular de la
          Información, serán objeto de Tratamiento para las finalidades
          autorizadas por el titular, en especial para identificación,
          validación de la información, cotejar identidad y establecer los datos
          de contacto para ubicación y notificaciones, tanto presentes como
          futuras a través del Tratamiento de los mismos. Con ocasión del
          Tratamiento de los datos personales y mediante el mismo, LA NACIONAL
          podrá obtener, compilar, intercambiar, enviar, adquirir, divulgar
          restrictivamente y dentro del alcance expresamente autorizado lo datos
          personales contenidos o no en ficheros, archivos, bases de datos o
          medios semejantes desde la captura o recolección directa o indirecta
          en otras bases de datos públicas o privadas, su almacenamiento,
          transferencia, actualización, uso o empleo, circulación y supresión,
          incluyendo datos biométricos; tales como huellas dactilares, registro
          de imagen y de voz; y, demás datos sensibles de conformidad con la
          Ley. El Tratamiento de los datos personales objeto de Tratamiento
          tienen además como finalidades las de: (1) mantener una comunicación
          de información de utilidad para las partes con ocasión de los vínculos
          contractuales que exista o llegaren a existir entre LA NACIONAL y el
          Titular de la Información. (2). Informar las modificaciones en
          desarrollo de los vínculos contractuales con el Titular de la
          Información. (3) Evaluar la calidad de los servicios ofrecidos por LA
          NACIONAL. (4) Realizar estudios internos sobre los hábitos de los
          Titulares de la Información. (5) Enviar a través de medio seguro la
          información a aquellas jurisdicciones en donde por contingencias se
          tengan “back-ups” o respaldos de la información. (6) Transmitir
          ofertas de servicios que puedan ser útiles o adecuadas para el titular
          de la información, a título individual o con ocasión de las alianzas
          comerciales que contraiga LA NACIONAL.
        </span>
      </p>

      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONA DE LICORES DE COLOMBIA S.A.S.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Esta aplicación es propiedad de LA NACIONAL, esta sociedad se
          encuentra identificada con el NIT 900.378.088-5, su dirección de
          notificaciones es: Carrera 18 No. 12- 34/36 de la Ciudad de Bogotá
          (Colombia), (+57 1) 432 56 10: </span
        ><span lang="ES-CO"
          ><a href="mailto:info@lanacionaldelicores.com"
            ><span style="font-family:sans-serif"
              >info@lanacionaldelicores.com</span
            ></a
          ></span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif"
          >DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La información, documentos (imágenes, vídeos, herramientas, gráficos
          y demás), marcas, nombres, logos y demás material que se encuentra en
          esta aplicación móvil, está protegido conforme a lo establecido en la
          legislación colombiana e internacional sobre derechos de autor,
          propiedad intelectual e industrial; por lo tanto, su uso está
          exclusivamente delimitado a fines personales de revisión, compra y
          consulta. De tal suerte que, el uso no autorizado que infrinja las
          leyes colombianas, a través de su venta, distribución, copia,
          modificación o adaptación o cualquier otra conducta prohibida, será
          sancionado de acuerdo a lo establecido por las normas
          aplicables.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Así las cosas, no podrán entenderse por el hecho de la publicación de
          los contenidos que se encuentran en la aplicación móvil, que se
          concede algún tipo de licencia de uso de marca, derechos de autor o
          propiedad intelectual a quien visita, consulta o realiza compras a
          través de esta aplicación.</span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif">USO AUTORIZADO.</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La aplicación móvil podrá ser utilizada para realizar compras y
          cotizaciones de productos importados y/o nacionales que LA NACIONAL
          tendrá disponible para la entrega a los usuarios, así como la
          información de usos y características de los productos y servicios
          ofrecidos, que están diseñados únicamente con fines informativos del
          usuario.</span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif">USOS PROHIBIDOS</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La información publicada en la aplicación móvil no podrá ser objeto
          de comercialización, distribución, copia o modificación.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >No está autorizado el acceso a cuentas o información confidencial de
          los usuarios, así como la violación de la red y/o servidores o
          cualquier otro acto que atente contra la seguridad de las
          transacciones y de la aplicación móvil.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La información que el usuario registre debe ser veraz y completa; se
          prohíbe todo acto de suplantación de identidad y/o información
          incorrecta que no corresponda a la persona natural o jurídica que hace
          uso de la aplicación móvil.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >En todo caso, únicamente se permitirá a personas mayores de edad,
          registrarse y efectuar operaciones de compra a través de esta
          aplicación móvil.</span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align:center">
        <span lang="ES-CO" style="font-family:sans-serif"
          >INFORMACIÓN DE LOS USUARIOS.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Para realizar pedidos online será necesario el registro por parte de
          los usuarios en la aplicación móvil, para tal efecto se requiere de
          información personal y confidencial. Esta información será de uso
          exclusivo de LA NACIONAL, salvo la información que se requiera para
          efectos de procesar las transacciones de pago.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Buscando la seguridad en las transacciones, el usuario declara que
          conoce, acepta y cumple las políticas de seguridad de la información
          que le ha suministrado la entidad financiera que le suministra el
          medio de pago electrónico y declara que el procedimiento de pago se
          hace por una pasarela de pagos, aliado de LA NACIONAL.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Disposiciones generales y contractuales.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Procuramos y ponemos nuestro mejor esfuerzo en que el ingreso a esta
          aplicación móvil sea seguro y su información personal se encuentra
          resguardada; en todo caso, no podemos hacernos responsables por virus,
          demoras en la operación o transmisión, errores tecnológicos,
          manipulación por terceros no autorizados, cualquier evento de invasión
          o manipulación tecnológica o algún otro error en el funcionamiento de
          la aplicación móvil. En consecuencia, toda transacción está sujeta a
          verificación posterior por parte de nuestra empresa y confirmación por
          parte del usuario, y la misma se sujeta a condición resolutoria cuando
          se presente alguna de las situaciones antes mencionadas.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >En todo caso, únicamente se permitirá a personas mayores de edad,
          registrarse y efectuar operaciones de compra a través de esta
          aplicación móvil.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Así mismo, eventualmente www.lanacionaldelicores.com puede presentar
          errores involuntarios, sí usted los detecta le agradecemos que los
          ponga nuestro conocimiento escribiendo al e-mail:
          info@lanacionaldelicores.com o comunicándose a la línea: (+57 1) 432
          56 10.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif">Medios de pago</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL, previo al despacho de los productos adquiridos,
          verificará que se haya efectuado el pago del pedido, a través de la
          pasarela de pago.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Pese a que esta aplicación móvil cuenta con conexiones seguras y
          confiables para la realización de transacciones electrónicas, LA
          NACIONAL se exime de responsabilidad por cualquier daño que se pueda
          generar ante alguna falla en las operaciones o comunicaciones de las
          entidades Financieras receptoras de los pagos efectuados.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Cargos por envío, impuestos y costos transaccionales</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >El comprador deberá asumir los costos de transporte de los productos
          adquiridos, el pago de los impuestos a que haya lugar en razón de su
          compra y los costos transaccionales asociados a comisiones bancarias y
          de la pasarela de pago; en todo caso, el valor del transporte será
          informado al momento de generar el pedido.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Promociones y Descuentos</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Todas las promociones y descuentos inscritos en la página/app son
          exclusivos de la aplicación móvil y no son reclamables en ningún punto
          físico de LA NACIONAL. Las Promociones y Descuentos son válidas hasta
          agotar existencias. Las reservas de mercancía que se efectúen en la
          aplicación móvil son sujetas a cambio de precio hasta que el pago se
          efectúe por el Usuario.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Promesa de entrega</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >El tiempo de entrega dependerá del tipo de Usuario con el que haya
          registrado la cuenta el comprador, si es Premium el pedido tendrá un
          estimado de entrega entre 30 y 110 minutos, y en caso de ser
          Corporativo, para domicilio inmediato la entrega se realizará en el
          transcurso del mismo dia que se confirme el pago o el comprador tendrá
          la oportunidad de programar su pedido en una fecha y hora específica.
          No obstante, ante circunstancias imprevistas, ajenas a la voluntad de
          LA NACIONAL e irresistibles, que llegasen a presentar retrasos en las
          entregas, tales circunstancias serán informadas telefónicamente o por
          vía electrónica al comprador o cualquier otro medio idóneo.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La venta estará sujeta a disponibilidad del inventario, si al
          realizar el alistamiento del producto no hay disponibilidad del mismo
          LA NACIONAL lo informará en la aplicación móvil y/o telefónica al
          comprador.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif">Devoluciones</span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Cuando el producto entregado presente algún defecto el comprador
          podrá reclamar a quien se lo entrega de forma inmediata, ante lo cual
          se realizará la devolución del dinero en los tiempos establecidos en
          la ley o el comprador podrá solicitar a su elección un producto de las
          mismas características, teniendo en cuenta el tiempo de entrega
          establecido en estos términos y condiciones de uso.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La garantía de cualquier producto será la ofrecida por el fabricante,
          por lo cual LA NACIONAL, en el evento en que se requiera, contactará
          al fabricante o distribuidor mayorista en Colombia en el caso que sea
          extranjero para el cubrimiento de la garantía exigida.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Si resulta procedente la garantía del producto, se efectuará la
          reposición del mismo de forma gratuita.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >La garantía no será procedente si el diagnóstico emitido es:</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Hubo fuerza mayor o caso fortuito</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >El hecho de un tercero</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >El uso indebido del bien por parte de consumidor final.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >LA NACIONAL no se hace responsable por el uso indebido que se realice
          de los productos, así, como del daño que se pudiere ocasionar con los
          mismos por su uso indebido, adulteración, reempaquetamiento y en
          general toda aquella actividad que aun estando permitida por la ley
          involucre un reacondicionamiento de las características del producto o
          de su empaquetado.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >El comprador con el hecho de aceptar los productos distribuidos y
          comercializados por LA NACIONAL, está aceptando que la calidad de los
          mismos es competencia exclusiva del fabricante directo y que las
          condiciones de empaquetamiento y calidad del producto se reciben del
          fabricante o importador directo.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Política de precios</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Informamos a los usuarios de la aplicación móvil, que los pedidos
          realizados, deberán estar por encima del tope mínimo especificado en
          la aplicación.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >Compromiso con la Seguridad</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span lang="ES-CO" style="font-family:sans-serif"
          >En relación a nuestra aplicación móvil, LA NACIONAL, declara que
          empleamos mecanismos para asegurar que la información y los Datos
          Personales que usted proporciona no sean extraviados, mal utilizados o
          modificados inapropiadamente. Esos controles incluyen políticas de
          protección de datos personales y respaldo periódico de bases de
          datos.</span
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
/* Style Definitions */
.terminos {
  width: 100%;
}

.WordSection1 {
  width: 80%;
  margin: auto;
  padding: 2% 0;
}

p {
  font-size: 1vw !important;
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
  line-height: 115%;
  font-size: 11pt;
  font-family: sans-serif;
}
a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}
.MsoChpDefault {
  font-size: 11pt;
  font-family: sans-serif;
}
.MsoPapDefault {
  margin-bottom: 10pt;
  line-height: 115%;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 70.85pt 85.05pt 70.85pt 85.05pt;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}

@media all and (max-width: 1024px) and (min-width: 600px) {
  p {
    font-size: 2vw !important;
  }
}

@media all and (max-width: 600px) {
  .WordSection1 {
    width: 80%;
    margin: 5% auto;
  }
  p {
    font-size: 3vw !important;
  }
}
</style>
