<template>
  <div class="tarjeta">
    <el-row v-if="derecha">
      <el-col class="cardText" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardText__div">
          <div
            class="cardText__div__container"
            v-bind:class="{ noTitle: !hayTitulo }"
          >
            <h1 v-if="titulo">{{ titulo }}</h1>
            <p>{{ texto }}</p>
            <el-link v-if="link" type="success" :href="link" target="_blank"
              >Visita su página
            </el-link>
          </div>
        </div></el-col
      >
      <el-col class="cardImg" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardImg__div">
          <img class="cardImg__div__img" v-lazy="imagen" />
        </div>
      </el-col>
    </el-row>

    <el-row v-else-if="celular">
      <el-col class="cardText" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardText__div">
          <div
            class="cardText__div__container2"
            v-bind:class="{ noTitle: !hayTitulo }"
          >
            <h1 v-if="titulo">{{ titulo }}</h1>
            <p>{{ texto }}</p>
            <a v-if="link" :href="link">Fundación Ciudad de Dios</a>
          </div>
        </div></el-col
      >
      <el-col class="cardImg" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardImg__div">
          <img class="cardImg__div__img" v-lazy="imagen" /></div
      ></el-col>
    </el-row>

    <el-row v-else>
      <el-col class="cardImg" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardImg__div">
          <img class="cardImg__div__img" v-lazy="imagen" /></div
      ></el-col>
      <el-col class="cardText" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardText__div">
          <div
            class="cardText__div__container2"
            v-bind:class="{ noTitle: !hayTitulo }"
          >
            <h1 v-if="titulo">{{ titulo }}</h1>
            <p>{{ texto }}</p>
          </div>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    derecha: Boolean,
    titulo: String,
    texto: String,
    link: String,
    imagen: String,
  },
  data() {
    return {
      hayTitulo: true,
      celular: false,
    };
  },
  mounted() {
    if (this.titulo == undefined) {
      this.hayTitulo = false;
    }
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.celular = true;
    }
  },
};
</script>
<style scoped>
.tarjeta {
  margin-top: 2%;
}

.cardImg {
  height: 40vw;
}

.cardText {
  height: 40vw;
  display: flex;
  align-items: flex-end;
}

.cardText__div {
  width: 100%;
  height: 90%;
  background-color: #f6f7f5;
}

.noTitle {
  height: 80% !important;
}

.noTitle p {
  text-align: justify;
}

.cardText__div__container {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.cardText__div__container2 {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  text-align: right;
}

.cardImg__div {
  height: 100%;
}
.cardImg__div__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  text-align: left;
  margin: 2% 0;
  color: #1e143c;
  font-size: 2.5vw;
}

p {
  margin: 2% auto;
  color: #1e143c;
  font-size: 1.5vw;
}

.el-link {
  font-size: 2vw;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
  .cardImg__div__img {
    height: 30vw;
  }
}

@media all and (max-width: 600px) {
  .cardText__div__container {
    width: 90%;
  }

  .cardText__div__container2 {
    width: 90%;
  }

  h1 {
    font-size: 5vw;
  }

  p {
    font-size: 3.5vw;
  }

  .el-link {
    font-size: 4vw;
  }

  .cardImg {
    height: 80vw;
  }

  .cardText {
    height: 80vw;
  }

  .cardImg__div__img {
    height: 80vw;
  }
}
</style>
