<template>
  <div class="home">
    <el-row class="container">
      <el-carousel
        v-if="width > 700"
        indicator-position="outside"
        class="carousel1"
        :interval="4000"
        :type="tipoCarousel"
      >
        <el-carousel-item v-for="index in 4" :key="index">
          <img
            class="image1"
            v-lazy="
              'https://storage.googleapis.com/imageneswebsite/carruselhome/Bienvenida_' +
                index +
                '.jpg'
            "
          />
        </el-carousel-item>
      </el-carousel>
      <Carousel
        v-else-if="width < 700"
        :value="imagenes"
        :numVisible="3"
        :numScroll="1"
        :responsiveOptions="responsiveOptions"
        class="custom-carousel2"
        :circular="true"
        :autoplay="false"
      >
        <template #item="slotProps">
          <div class="masificador-item">
            <div class="masificador-item-content">
              <div class="p-mb-3">
                <img
                  class="image1"
                  :src="
                    'https://storage.googleapis.com/imageneswebsite/carruselhome/Bienvenida_' +
                      slotProps.data.index +
                      '.jpg'
                  "
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </template>
      </Carousel>
    </el-row>

    <el-row class="container" justify="space-around">
      <el-col :xs="24" :sm="9" :md="9" :lg="8" :xl="8"
        ><div>
          <img
            class="image2"
            v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/Celular_app.jpg'"
          /></div
      ></el-col>
      <el-col :xs="24" :sm="15" :md="15" :lg="16" :xl="16"
        ><div class="appInfo">
          <el-row type="flex" class="row-bg" justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="23"
              ><div class="appInfo__title">
                <h1>COMPRA ON-LINE</h1>
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="23"
              ><div class="appInfo__text">
                <p>
                  {{ textoApp }}
                </p>
                <div>
                  <p style="text-align: center; font-weight: bolder;">Encuéntranos como: La Nacho</p>
                </div>
              </div></el-col
            >
          </el-row>

          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="23"
              ><div class="appInfo__images">
                <img
                  class="app"
                  v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/AppStore.jpg'"
                />
                <img
                  class="app"
                  v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/PlayStore.jpg'"
                />
                <img
                  class="qr"
                  v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/qr.png'"
                /></div
            ></el-col>
          </el-row></div
      ></el-col>
    </el-row>

    <h1 class="puntos">NUESTROS PUNTOS DE VENTA</h1>
    <el-row class="container">
      <el-carousel class="carousel2" :interval="4000" arrow="always">
        <el-carousel-item v-for="sucursal in sucursales" :key="sucursal.nombre">
          <el-row style="height: 100%">
            <el-col
              class="sedeInfo"
              :xs="24"
              :sm="13"
              :md="13"
              :lg="13"
              :xl="13"
              ><div class="info2">
                <h2>SEDE</h2>
                <h1>{{ sucursal.nombre }}</h1>
                <p>{{ sucursal.direccion }}</p>
                <p>Horario: {{ sucursal.horarios }}</p>
                <button @click="abrirLink(sucursal.link)">
                  ¿Cómo llegar?
                </button>
              </div></el-col
            >
            <el-col class="sedeImg" :xs="24" :sm="11" :md="11" :lg="11" :xl="11"
              ><div class="imgSede">
                <img class="image4" v-lazy="sucursal.url"  /></div
            ></el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </el-row>

    <h1 class="ubicaciones">
      Encuentra aquí nuestras ubicaciones
    </h1>

    <el-row class="container container__mapa">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
        ><div>
          <GmapMap
            :options="{ gestureHandling: 'cooperative' }"
            :center="center"
            :zoom="mapZoom"
            class="mapa"
          >
            <GmapInfoWindow
              :options="{
                maxWidth: 300,
                pixelOffset: { width: 0, height: -35 },
              }"
              :position="infoWindow.position"
              :opened="infoWindow.open"
              @closeclick="infoWindow.open = false"
            >
              <div v-html="infoWindow.template"></div>
            </GmapInfoWindow>

            <GmapMarker
              :key="i"
              v-for="(m, i) in sucursales"
              :position="m.coordinates"
              :clickable="true"
              @click="toggleInfoWindow(m, i)"
            ></GmapMarker>
          </GmapMap></div
      ></el-col>
    </el-row>

    <el-row class="container container__reviews">
      <el-col
        class="container__reviews__col1"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        ><div class="compromiso" @click="abrirCompromiso()">
          <img
            v-lazy="'https://storage.googleapis.com/imageneswebsite/compromisoSocial/compromisoSocial.jpg'"
          />
          <div class="texto">
            NUESTRO <br />
            COMPROMISO SOCIAL
          </div>
        </div></el-col
      >
      <el-col
        class="container__reviews__col2"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        ><div class="reviews">
          <el-row class="reviews__general">
            <el-col
              class="reviews__general__col1"
              :xs="5"
              :sm="9"
              :md="6"
              :lg="4"
              :xl="3"
              ><div class="reviews__general__img">
                <img
                  v-lazy="'https://storage.googleapis.com/imageneswebsite/logos/Sello_lndl.png'"
                /></div
            ></el-col>
            <el-col
              class="reviews__general__col2"
              :xs="19"
              :sm="15"
              :md="18"
              :lg="20"
              :xl="21"
              ><div class="reviews__general__info">
                <h1>La Nacional de Licores</h1>
                <el-rate
                  v-model="value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value} points"
                >
                </el-rate></div
            ></el-col>
          </el-row>
          <el-row class="reviews__all">
            <div class="reviews__all__col__div">
              <ul
                class="infinite-list"
                v-infinite-scroll="load"
                style="overflow:auto"
              >
                <li
                  v-for="review in listReviews.slice(0, count)"
                  :key="review.id"
                  class="infinite-list-item"
                >
                  <h2>{{ review.reviewerName }}</h2>
                  <el-rate
                    v-model="review.starRating"
                    disabled
                    show-score
                    text-color="#ff9900"
                    score-template="{value} points"
                  >
                  </el-rate>
                  <p>{{ review.comment }}</p>
                  <el-divider></el-divider>
                </li>
              </ul>
            </div>
          </el-row></div
      ></el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "../axios/index.js";
export default {
  data() {
    return {
      imagenes: [
        {
          index: 1,
        },
        {
          index: 2,
        },
        {
          index: 3,
        },
        {
          index: 4,
        },
      ],
      width: 0,
      textoApp: "",
      tipoCarousel: "card",
      mapZoom: 12,
      count: 0,
      listReviews: [],
      value: 5,
      currentLocation: {
        lat: "",
        lng: "",
      },
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      center: {
        lat: 4.67,
        lng: -74.1,
      },
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
      currentMidx: null,
      id: "1011044050589",
      location: "ChIJ4QYDlgyZP44Ri38QJ0uu0d8",
      sucursales: [
        {
          nombre: "CENTRO",
          direccion: "Carrera 18 No. 12-36",
          horarios: "Lunes a Sábado 08:00 a.m. a 06:00 p.m.",
          url:
            "https://storage.googleapis.com/imageneswebsite/Sedes/Lndl_18.jpg",
          coordinates: {
            lat: 4.6057606703345915,
            lng: -74.08393947421324,
          },
          link: "https://goo.gl/maps/vXYK9o1s6NSiKJos6",
        },
        {
          nombre: "Calle 72",
          direccion: "Calle 72 No. 20-90",
          horarios:
            "Lunes a Miércoles de 8:00 am a 6:00 p.m. Jueves a Sábado de 08:00 am a 11:00 p.m.",
          url:
            "https://storage.googleapis.com/imageneswebsite/Sedes/Lndl_72.jpg",
          coordinates: {
            lat: 4.660359743781513,
            lng: -74.06297477781345,
          },
          link: "https://goo.gl/maps/DtREyfzfUBY4D1U59",
        },
        {
          nombre: "Calle 80",
          direccion: "Calle 80 No. 114-73",
          horarios:
            "Lunes a Sábado de 08:00 am a 06:00 p.m.",
          url:
            "https://storage.googleapis.com/imageneswebsite/Sedes/Lndl_80.jpg",
          coordinates: {
            lat: 4.723624063057693,
            lng: -74.12322184643155,
          },
          link: "https://goo.gl/maps/5wENn6fHNwkCKj2U9",
        },
        {
          nombre: "Bosa",
          direccion: "Calle 65 Sur No. 78D-10",
          horarios: "Lunes a Sábado 08:00 a.m. a 06:00 p.m.",
          url:
            "https://storage.googleapis.com/imageneswebsite/Sedes/Lndl_65.png",
          coordinates: {
            lat: 4.6030945,
            lng: -74.1835417,
          },
          link: "https://goo.gl/maps/KvMXb1dn8yjmvEAh8",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  methods: {
    toggleInfoWindow: function(marker, idx) {
      this.infoWindow.position = marker.coordinates;
      // this.center = marker.coordinates;
      this.infoWindow.template = `<b>${marker.nombre}</b><br>${marker.direccion}<br>${marker.horarios}`;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWindow.open = !this.infoWindow.open;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWindow.open = true;
        this.currentMidx = idx;
      }
    },
    abrirCompromiso() {
      this.$router.push({ name: "quienesSomos", params: { redirect: true } });
    },
    abrirLink(link) {
      window.open(link);
    },
    load() {
      this.count += 2;
    },
  },
  beforeMount() {
    this.width = window.innerWidth > 0 ? window.innerWidth : screen.this.width;
    console.log(this.width);
    if (this.width < 768) {
      this.mapZoom = 10.3;
      this.center.lat = 4.71;
      this.tipoCarousel = "";
    } else if (this.width < 1000) {
      this.mapZoom = 11;
      this.center.lat = 4.69;
      this.tipoCarousel = "";
    } else if (this.width < 1024) {
      this.tipoCarousel = "";
    } else if (this.width > 2000) {
      this.tipoCarousel = "card";
    } else if (this.width > 1400) {
      this.tipoCarousel = "card";
    }
  },
  mounted() {
    axios.get("/texto/textoApp").then((response) => {
      if (response.status == 200) {
        this.textoApp = response.data.texto;
      }
    });

    axios.get("/reviews").then((response) => {
      if (response.status == 200) {
        // console.log(response.data);
        this.listReviews = response.data.sort(() => Math.random() - 0.5);
      }
    });
  },
};
</script>
<style scoped>
.container {
  margin-bottom: 40px;
}
.el-row {
  width: 100%;
  /* height: 100%; */
  /* margin-bottom: 20px; */
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.image1 {
  width: 100%;
  height: 100%;
}

.image2 {
  /* width: auto; */
  display: block;
  margin: auto;
  max-width: 98%;
  height: auto;
  width: 70%;
  border-radius: 60px;
}

.appInfo {
  padding-top: 8%;
}

.appInfo__title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.appInfo__title h1 {
  width: 80%;
  font-size: 2.5vw;
  color: #1e143c;
  margin: 0 0;
  font-weight: bolder;
}

.appInfo__text {
  padding: 0 50px;
}

.appInfo__text p {
  font-size: 1.5vw;
  vertical-align: middle;
  color: #1e143c;
  margin: 3%;
  text-align: center;
}

.appInfo__images {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
}

.appInfo__images img {
  margin: 0;
}

.app {
  float: left;
  max-width: 98%;
  height: auto;
  width: 30%;
  object-fit: contain;
  border-radius: 20px;
}

.qr {
  max-width: 98%;
  height: auto;
  width: 15%;
  border-style: outset;
  border-radius: 10px;
  /* padding-bottom: 20px; */
}

.appInfo__textFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.puntos {
  text-align: center;
  margin: 2% 0;
  font-size: 2.5vw;
  color: #1e143c;
  font-weight: bolder;
}

.sedeInfo {
  height: 100%;
  display: flex;
  background-color: #f6f7f5;
  /* height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative; */
}

.sedeImg {
  height: 100%;
}

.info2 {
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.info2 > h1,
.info2 > h2,
.info2 > p {
  color: #1e143c;
  margin: 0;
}

.info2 > h2 {
  font-size: 3vw;
  font-weight: normal;
}

.info2 > h1 {
  /* font-size: 100px; */
  font-size: 4vw;
}

.info2 > p {
  font-size: 1.5vw;
}

.info2 button {
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  width: 60%;
  background-color: #ff3e34;
  border: none;
  color: #fff;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vw;
  margin: 2vw 0;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
}

.imgSede {
  height: 100%;
  text-align: right;
  background-color: #f6f7f5;
}

.image4 {
  max-width: 100%;
  /* width: 100%; */
  height: 100%;
  object-fit: fill;
}

.container__mapa {
  height: 500px;
}

.mapInfo {
  height: 100%;
}

.textMap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f7f5;
  margin: auto;
}

.ubicaciones {
  font-size: 2.5vw;
  text-align: center;
  color: #1e143c;
  text-transform: uppercase;
}

.mapa {
  width: 100%;
  height: 500px;
  align-content: center;
  margin: auto;
}

.container__reviews {
    height: 400px;
  }

.container__mapa {
  height: 400px;
}

.mapa {
  height: 400px;
}

.carousel1 >>> .el-carousel__container {
  height: 400px;
}

.carousel2 >>> .el-carousel__container {
  height: 400px;
}

.container__reviews__col1 {
  object-fit: cover;
  cursor: pointer;
}

.container__reviews__col1:hover {
  opacity: 0.9;
}

.container__reviews__col1,
.container__reviews__col2 {
  height: 100%;
}

.compromiso,
.reviews {
  height: 100%;
}

.compromiso {
  position: relative;
  text-align: center;
  color: white;
  margin: 0 5%;
  border-radius: 5px 5px 5px 5px;
  box-shadow: -10px 10px 2px 2px rgb(243, 242, 239);
}

.compromiso img {
  border-radius: 1%;
  width: 100%;
  height: 100%;
}

.texto {
  text-align: initial;
  font-size: 2.5vw;
  font-weight: bolder;
  position: absolute;
  bottom: 6%;
  left: 4%;
}

.reviews {
  margin: 0 5%;
  border-radius: 5px 5px 5px 5px;
  box-shadow: -10px 10px 2px 2px rgb(243, 242, 239);
}

.reviews__general {
  height: 20%;
}

.reviews__title {
  display: flex;
  align-items: center;
  height: 10%;
  margin: 1% 0;
}

.reviews__general__col1,
.reviews__general__col2 {
  height: 100%;
  margin: 3% 0;
}

.reviews__general__img,
.reviews__general__info {
  height: 100%;
}

.reviews__general__img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews__general__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviews__general__info h1 {
  margin: 0;
  font-size: 2.5vw;
}

.reviews__general__img img {
  float: left;
  max-width: 100%;
  height: auto;
  width: 70%;
  object-fit: contain;
}

.reviews__title__col {
  height: 100%;
}

.reviews__title__col__div {
  height: 100%;
  display: flex;
  align-items: center;
}

.reviews__title__col__div h1 {
  font-weight: 500;
  font-size: 2vw;
}

.reviews__all::before {
  content: none;
}

.reviews__all::after {
  content: none;
}

.reviews__all {
  height: 80%;
  margin: 0;
}

.reviews__all__col {
  height: 100%;
}

.reviews__all__col__div {
  height: 80%;
}

.infinite-list {
  margin: 0;
  height: 100%;
  list-style: none;
}

.infinite-list-item {
  margin: 2% 0;
}

.infinite-list-item h2 {
  font-size: 2vw;
  margin: 2% 0;
}

.infinite-list-item p {
  font-size: 1.5vw;
  margin: 0;
  font-weight: normal;
}

@media all and (min-width: 1900px) {
  .container__reviews {
    height: 500px;
  }

  .container__mapa {
    height: 500px;
  }

  .mapa {
    height: 500px;
  }

  .carousel1 >>> .el-carousel__container {
    height: 500px;
  }

  .carousel2 >>> .el-carousel__container {
    height: 500px;
  }
}

@media all and (min-width: 2000px) {
  .info2 > h2 {
    font-size: 2vw;
    font-weight: normal;
  }

  .info2 > h1 {
    /* font-size: 100px; */
    font-size: 2vw;
  }

  .info2 > p {
    font-size: 1.5vw;
  }

  .container__mapa {
    height: 500px;
  }

  .mapa {
    height: 500px;
  }

  .container__reviews {
    height: 700px;
  }

  .carousel1 >>> .el-carousel__container {
    height: 600px;
  }

  .carousel2 >>> .el-carousel__container {
    height: 600px;
  }
}

@media all and (max-width: 1400px) and (min-width: 1000px) {
  .container__reviews {
    height: 300px;
  }
  .appInfo__images img {
    margin: 10% 0;
  }

  .container__mapa {
    height: 450px;
  }

  .mapa {
    height: 450px;
  }

  .carousel1 >>> .el-carousel__container {
    height: 300px;
  }

  .carousel2 >>> .el-carousel__container {
    height: 300px;
  }
}

@media all and (max-width: 1000px) and (min-width: 600px) {
  .carousel1 >>> .el-carousel__container {
    height: 400px;
  }

  .carousel2 >>> .el-carousel__container {
    height: 300px;
  }

  .image1 {
    object-fit: fill;
  }

  .image4 {
    object-fit: cover;
  }

  .container__mapa {
    height: 300px;
  }
  .mapa {
    height: 300px;
  }

  .texto {
    font-size: 3.5vw;
  }

  .container__reviews {
    height: 300px;
  }

  .compromiso,
  .reviews {
    box-shadow: -7px 7px 1px 1px rgb(243, 242, 239);
  }

  .reviews__general {
    height: 30%;
  }

  .reviews__title {
    height: 10%;
  }

  .reviews__all {
    height: 60%;
  }

  .reviews__general__img img {
    width: 50%;
  }

  .reviews__general__info h1 {
    margin: 0;
    font-size: 3vw;
  }

  .reviews__title__col__div h1 {
    font-weight: 500;
    font-size: 4vw;
  }

  .infinite-list-item h2 {
    font-size: 3vw;
  }

  .infinite-list-item p {
    font-size: 3vw;
  }

  .appInfo__images {
    padding: 0 70px 0 50px;
  }

  .appInfo__images img {
    margin: 10% 0;
  }

  .app {
    width: 35%;
  }

  .qr {
    width: 20%;
  }
}

@media all and (max-width: 600px) {
  .carousel1 >>> .el-carousel__container {
    height: 46vw;
    overflow: scroll;
  }

  .custom-carousel2 >>> .p-carousel-container .p-link {
    display: none !important;
  }

  .carousel2 >>> .el-carousel__container {
    height: 400px;
  }

  .appInfo__images {
    flex-direction: column;
  }

  .image1 {
    max-height: 100%;
    height: 250px;
  }

  .image2 {
    width: 70%;
    border-radius: 40px;
  }

  .appInfo__title h1 {
    width: 100%;
    font-size: 10vw;
    margin: 5% 0;
  }

  .appInfo__text{
    padding: 0 30px;
  }

  .appInfo__text p {
    font-size: 4.5vw;
    text-align: center;
  }

  .app {
    width: 70%;
  }

  .appInfo__images {
    padding: 0 50px;
  }

  .appInfo__images img {
    margin: 10px 0;
  }

  .qr {
    width: 50%;
  }

  .appInfo__textFooter h1 {
    font-size: 10vw;
  }

  .sedeInfo,
  .sedeImg {
    height: 50%;
  }

  .info2 {
    text-align: center;
    width: 100%;
  }

  .info2 > h2 {
    font-size: 4vw;
  }

  .info2 > h1 {
    font-size: 5vw;
  }

  .info2 > p {
    font-size: 4vw;
  }

  .info2 button {
    font-size: 4vw;
    margin: 2vw auto;
  }

  .image4 {
    width: 100%;
    object-fit: cover;
  }

  .puntos {
    font-size: 7vw;
    margin: 0;
  }

  .ubicaciones {
    font-size: 7vw;
    margin: 0;
  }

  .container__mapa {
    height: 200px;
  }

  .container__reviews {
    height: 500px;
  }

  .mapInfo {
    height: 50%;
  }

  .textMap > h1 {
    font-size: 9vw;
    text-align: center;
  }

  .mapa {
    height: 200px;
  }

  .texto {
    font-size: 7vw;
  }

  .compromiso,
  .reviews {
    box-shadow: -5px 5px 1px 1px rgb(243, 242, 239);
  }

  .compromiso img:active {
    transform: scale(0.9);
  }

  .container__reviews__col1 {
    height: 40%;
  }

  .container__reviews__col2 {
    height: 60%;
    margin: 10px 0;
  }

  .reviews__general {
    height: 20%;
  }

  .reviews__title {
    height: 10%;
  }

  .reviews__title__col__div h1 {
    font-weight: 500;
    font-size: 4vw;
  }

  .reviews__all {
    height: 80%;
  }

  .reviews__general__img img {
    width: 80%;
  }

  .reviews__general__info h1 {
    margin: 0;
    font-size: 6vw;
  }

  .infinite-list-item h2 {
    font-size: 6vw;
  }

  .infinite-list-item p {
    font-size: 5vw;
  }
}
</style>
