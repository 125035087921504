<template>
  <div id="app">
    <Header :key="$route.fullPath" />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "La Nacho";
      },
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
/* @font-face {
  font-family: "QuincyCF";
  src: local("Quincy CF"), local("HelveticaNeue-Bold"),
    url(./assets/fonts/QuincyCF.ttf);
} */
#app {
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
