<template>
  <div>
    <h1>¡TE VISITAMOS!</h1>
    <p class="titleText">
      Agenda una cita con nosotros para visitarte y descubrir los beneficios de
      tener un solo proveedor para todos tus licores. Te ayudamos a descargar
      nuestra app y guiarte en el proceso de proveer tu negocio sin salir de
      él.
      <nav>¡Contáctanos! Linea exclusiva La Nacho APP <i @click="abrirWhat" class="fab fa-whatsapp"></i></nav>
    </p>
    <el-row class="cardGestor">
      <el-col class="cardText" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardText__div">
          <div class="cardText__div__container">
            <h1>¿Qué es un Gestor de Mercado?</h1>
            <p>
              Nuestros gestores de mercado estan aquí para visitarte y
              vincularte a nuestra app facilitando tu día a día. Registrate y
              realiza tus pedidos desde la comodidad de tu establecimiento con
              los mejores precios en la palma de tus manos.
            </p>
          </div>
        </div></el-col
      >
      <el-col class="cardImg" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
        ><div class="cardImg__div">
          <img
            class="cardImg__div__img"
            v-lazy="'https://storage.googleapis.com/imageneswebsite/gestores/Principal.png'"
          /></div
      ></el-col>
    </el-row>

    <div class="card">
      <Carousel
        :value="gestores"
        :numVisible="3"
        :numScroll="1"
        :responsiveOptions="responsiveOptions"
        class="custom-carousel"
        :circular="true"
        :autoplay="false"
      >
        <template #item="slotProps">
          <div class="masificador-item">
            <div class="masificador-item-content">
              <div class="p-mb-3">
                <img
                  v-lazy="
                    'https://storage.googleapis.com/imageneswebsite/gestores/' +
                      slotProps.data.nombre.replace(/ /g,'') + '.png'
                  "
                  class="masificador-image"
                />
              </div>
              <div>
                <h1 class="p-mb-1">{{ slotProps.data.nombre }}</h1>
                <h3 class="p-mt-0 p-mb-3">ID. {{ slotProps.data.id }}</h3>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import axios from "../axios/index.js";
export default {
  data() {
    return {
      gestores: null,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  methods: {
    abrirWhat() {
      window.open(
        "https://api.whatsapp.com/send?phone=573244759348&text=%C2%A1Hola%21%20Gracias%20por%20comunicarte%20con%20La%20Nacional%20de%20Licores.%20%C2%BFQuieres%20agendar%20una%20cita%20para%20tu%20establecimiento%3F%20%F0%9F%8D%BA%F0%9F%8D%BA",
        "_blank"
      );
    },
  },
  mounted() {
    axios.get("/gestores").then((response) => {
      if (response.status == 200) {
        // console.log(response.data);
        this.gestores = response.data;
      }
    });
  },
};
</script>
<style scoped>
.el-col {
  margin-bottom: 1%;
}
h1 {
  margin: 2% 0;
  text-align: center;
  color: #1e143c;
  font-size: 2.5vw;
}

p {
  margin: 2% auto;
  color: #1e143c;
  font-size: 1.5vw;
}

.titleText {
  margin: 2% 5%;
  color: #1e143c;
  font-size: 1.5vw;
  text-align: center;
}

.btn {
  height: 4vw;
  width: 4vw;
  /* font-size: 5vw !important; */
}

.fa-whatsapp {
  vertical-align: bottom;
  font-size: 3vw !important;
  color: rgb(117, 196, 45);
  cursor: pointer;
}

.fa-whatsapp:hover {
  opacity: 0.6;
}

.cardImg {
  height: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardText {
  height: 30vw;
  display: flex;
}

.cardText__div {
  width: 100%;
  height: 100%;
}

.cardText__div__container {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.cardText__div__container p {
  width: 100%;
}

.cardImg__div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardImg__div__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.card {
  background-color: #f6f7f5;
}

.masificador-item-content {
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
}

.masificador-item-content,
h1,
h3 {
  font-family: "Poppins", sans-serif;
  color: #1e143c;
  margin: 2% 0;
}

.masificador-item-content h1 {
  font-weight: bolder;
  font-size: 2vw;
}

.masificador-item-content h3 {
  font-size: 1.3vw;
  font-weight: normal;
}

.masificador-image {
  width: 70%;
  margin: 5% 0;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
  h1 {
    font-size: 3vw;
    margin-top: 6%;
  }

  p {
    font-size: 2vw;
  }

  .fa-whatsapp {
    font-size: 4vw !important;
  }

  .cardGestor {
    margin: 3% 0;
  }

  .cardImg__div__img {
    width: 70%;
    height: 100%;
  }

  .masificador-image {
    width: 70%;
  }

  .masificador-item-content h2 {
    font-size: 3vw;
  }

  .masificador-item-content h3 {
    font-size: 2.3vw;
  }
}

@media all and (max-width: 600px) {
  .el-col {
    margin-bottom: 8%;
  }
  h1 {
    font-size: 8vw;
    margin-top: 6%;
  }

  p {
    width: 100%;
    font-size: 4.5vw;
    text-align: center;
  }

  .masificador-image {
    width: 100%;
  }

  .titleText {
    width: 90%;
    font-size: 5vw;
    margin: 0 auto;
    text-align: center;
  }

  .btn {
    height: 4vw;
    width: 4vw;
    /* font-size: 5vw !important; */
  }

  .fa-whatsapp {
    font-size: 8vw !important;
  }

  .cardImg {
    height: 50%;
  }

  .cardText {
    height: 50%;
  }

  .cardImg__div__img {
    width: 90%;
    height: 100%;
  }

  .masificador-item-content h1 {
    font-size: 6.5vw;
  }

  .masificador-item-content h2 {
    font-size: 7vw;
  }

  .masificador-item-content h3 {
    font-size: 5.3vw;
  }
}
</style>
