<template>
  <div class="trabaja">
    <h1>TRABAJA CON NOSOTROS</h1>
    <p>
      En la Nacional de Licores apreciamos tu interés en pertenecer a esta gran
      familia. Aquí podrás escribirnos un poco sobre ti, compartirnos tu
      información y adjuntar tu hoja de vida. Nuestro equipo se pondrá en
      contacto contigo.
    </p>
    <form>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col class="marg" :xs="24" :sm="11" :md="11" :lg="11" :xl="11"
          ><div>
            <el-input
              placeholder="Nombres y Apellidos"
              v-model="form.nombre"
            ></el-input></div
        ></el-col>
        <el-col class="marg" :xs="24" :sm="11" :md="11" :lg="11" :xl="11"
          ><div>
            <el-input
              type="number"
              placeholder="Celular"
              v-model.number="form.celular"
            ></el-input></div
        ></el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col class="marg" :xs="24" :sm="11" :md="11" :lg="11" :xl="11"
          ><div>
            <el-input placeholder="E-mail" v-model="form.email"></el-input></div
        ></el-col>
        <el-col class="marg" :xs="24" :sm="11" :md="11" :lg="11" :xl="11"
          ><div class="boton">
            <el-upload
              class="upload-demo"
              :action="url"
              :show-file-list="true"
              :name="'aplicanteInfo'"
              :auto-upload="true"
              :on-change="handleChange"
              :on-success="archivoSubido"
              :file-list="fileList"
              :on-remove="handleRemove"
              :limit="1"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
            >
              <el-button class="file" size="small" type="primary"
                >Adjunta tu hoja de vida</el-button
              >
              <div slot="tip" class="el-upload__tip">
                Sube tu hoja de vida con un tamaño menor de 500mb y en formato
                PDF
              </div>
            </el-upload>
          </div></el-col
        >
      </el-row>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col class="marg" :xs="24" :sm="23" :md="23" :lg="23" :xl="23"
          ><div>
            <el-input
              type="textarea"
              :rows="filas"
              placeholder="Descripción"
              v-model="form.descripcion"
            >
            </el-input></div
        ></el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="left">
        <el-col class="sendButton" :xs="24" :sm="23" :md="23" :lg="23" :xl="24"
          ><div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onVerify"
              @expired="onCaptchaExpired"
              :loadRecaptchaScript="true"
              sitekey="6LdCGX0aAAAAAMWDIl_fiDf7vB6mLzW4as0izrcu"
              class="captcha"
            ></vue-recaptcha>
            <el-checkbox v-model="checked"
              >Estoy de acuerdo con la
              <router-link target="_blank" to="/terminos"
                >política de tratamiento de datos</router-link
              ></el-checkbox
            >
            <button @click="enviar" class="send">
              ENVIAR
            </button>
          </div></el-col
        >
      </el-row>
    </form>
  </div>
</template>
<script
src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
async
defer
></script>
<script>
import axios from "../axios/index.js";
import VueRecaptcha from "vue-recaptcha";
export default {
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      filas: 15,
      checked: false,
      url: "",
      form: {
        nombre: "",
        celular: "",
        email: "",
        descripcion: "",
        hojadevida: "",
        token: false,
      },
      fileList: [],
    };
  },
  methods: {
    onVerify(response) {
      if (response) {
        this.form.token = response;
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset();
    },
    validateEmail() {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.form.email
        )
      ) {
        this.$message.error("No has ingresado un correo valido");
        return false;
      }
      return true;
    },
    validateCampos() {
      if (this.form.nombre && this.form.celular && this.form.descripcion) {
        if (this.checked == false) {
          this.$message.error("Acepta los terminos y condiciones");
          return false;
        }
        return true;
      }
      this.$message.error("Todos los campos son obligatorios");
      return false;
    },
    enviar(e) {
      e.preventDefault();
      const that = this;
      if (that.validateEmail() && that.validateCampos()) {
        if (that.form.hojadevida == "") {
          that.$message.error("Debes subir tu hoja de vida");
        } else {
          if (that.form.token) {
            axios
              .post("/aplicantesInfo", that.form)
              .then((response) => {
                console.log(response);
                if (response.status == 200) {
                  // console.log(response);
                  that.$message({
                    message: "Tus datos se han subido correctamente",
                    type: "success",
                  });
                  that.$refs.recaptcha.reset();
                  that.checked = false;
                  that.form.nombre = "";
                  that.form.celular = "";
                  that.form.email = "";
                  that.form.descripcion = "";
                  that.form.hojadevida = "";
                  that.fileList = [];
                }
              })
              .catch(function(e) {
                console.log(e.response);
                that.$message.error(
                  "No se pudo verificar que no seas un robot intenta nuevamente el CAPTCHA"
                );
                that.$refs.recaptcha.reset();
              });
          } else {
            that.$message.error("Verifica que no eres un robot por favor!!");
          }
        }
      }
    },
    archivoSubido(file) {
      this.form.hojadevida = file.Path;
    },
    handleRemove() {
      this.form.hojadevida = null;
    },
    handleExceed() {
      this.$message.warning("Solo puedes subir Un archivo");
    },
    handleChange(file) {},
    beforeUpload(file) {
      if (file.size > 15000000) {
        this.$message.error("El archivo no puede superar los 500mb");
        return false;
      }

      if (file.type != "application/pdf") {
        this.$message.error("El archivo debe ser de tipo PDF");
        return false;
      }
    },
  },
  mounted() {
    this.url = process.env.VUE_APP_BASE_URL + "aplicantesFiles";
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.filas = 4;
    } else if (width < 1000) {
      this.filas = 10;
    }
  },
};
</script>
<style scoped>
.trabaja {
  margin: 2% 0;
}

form {
  height: 100%;
  width: 70%;
  margin: 2% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.el-input >>> .el-input__inner {
  font-family: "Poppins", sans-serif;
}

.el-textarea >>> .el-textarea__inner {
  font-family: "Poppins", sans-serif;
}

h1 {
  margin: 2% 0;
  text-align: center;
  color: #1e143c;
  font-size: 2.5vw;
}

p {
  margin: 0 5%;
  text-align: center;
  color: #1e143c;
  font-size: 1.5vw;
}

.el-row {
  width: 100%;
}

.el-upload {
  width: 100%;
}

.upload-demo >>> .el-upload--text {
  width: 100%;
}

.row-bg {
  margin: 0.5% 0;
}

.el-col {
  height: 100%;
}

.boton {
  text-align: center;
}

.file,
.send {
  height: 40px;
  font-family: "Poppins", sans-serif;
  background-color: #ff3e34;
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
  cursor: pointer;
  outline: none;
  font-weight: bolder;
  border-radius: 20px;
}
.file {
  width: 100%;
}

.sendButton {
  margin: 0 2%;
}

.el-checkbox {
  display: block;
  margin-top: 1%;
}

.send {
  margin-top: 1%;
  width: 30%;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
  h1 {
    font-size: 4vw;
  }

  p {
    font-size: 3vw;
  }

  .el-checkbox {
    margin-bottom: 2%;
  }

  .file,
  .send {
    font-size: 2vw;
  }
}

@media all and (max-width: 600px) {
  .row-bg {
    margin: 0;
  }

  .marg {
    margin: 2% 0;
  }

  h1 {
    font-size: 8vw;
  }

  p {
    font-size: 4.5vw;
  }
  .el-row {
    flex-direction: column;
  }

  .file,
  .send {
    font-size: 5vw;
  }

  .captcha {
    margin: auto;
    transform: scale(0.83);
    transform-origin: 0 0;
  }

  .el-checkbox {
    margin-bottom: 6%;
  }

  .el-checkbox >>> .el-checkbox__label {
    font-size: 2.5vw;
  }

  .sendButton {
    margin: 3% 0;
  }

  .send {
    margin-top: 2%;
    width: 100%;
  }
}
</style>
