<template>
  <div>
    <el-row class="header">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
        ><div class="container">
          <img
            class="container__img"
            v-lazy="'https://storage.googleapis.com/imageneswebsite/recursos/preguntasFondo.jpg'"
          />
          <div class="centered">PREGUNTAS FRECUENTES</div>
        </div></el-col
      >
    </el-row>
    <div class="questions">
      <h1>¿Tienes una pregunta?</h1>
      <p>
        Este espacio esta dispuesto para solucionar las inquietudes que tengas
        sobre nuestro servicio.
      </p>
      <el-collapse class="preguntas" accordion>
        <el-collapse-item
          v-for="pregunta in preguntas"
          :key="pregunta.id"
          :name="pregunta.id"
        >
          <template class="prueba" slot="title">
            <div class="collapse_title">
              {{ pregunta.pregunta }}
            </div>
          </template>
          <div class="div__respuesta">
            {{ pregunta.respuesta }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import axios from "../axios/index.js";
export default {
  data() {
    return {
      preguntas: null,
    };
  },
  mounted() {
    axios.get("/preguntas").then((response) => {
      if (response.status == 200) {
        this.preguntas = response.data;
      }
    });
  },
};
</script>
<style scoped>
/* forma rara de modificar estilos de Element a partir de clases que crea en compilacion por lo tanto usamos >>> para poder acceder a estas */
.el-collapse-item >>> .el-collapse-item__header {
  height: 4vw;
}

.el-collapse-item >>> .is-active {
  background-color: #1e143c;
}

.el-collapse-item >>> .is-active div {
  color: white;
}

.el-collapse-item >>> .is-active .el-icon-arrow-right {
  font-size: 3vw;
  color: white !important;
}

.el-collapse-item >>> .el-collapse-item__header .el-icon-arrow-right {
  font-size: 3vw;
  color: #1e143c;
}

.el-collapse-item >>> .el-collapse-item__content{
  padding: 10px 0;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.container__img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  filter: brightness(60%);
}

.centered {
  width: 100%;
  font-size: 2.5vw;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.questions {
  width: 70%;
  margin: 3% auto;
}

h1 {
  margin: 2% 0;
  text-align: left;
  color: #1e143c;
  font-size: 2.5vw;
  font-weight: bolder;
}

p {
  margin: 1% auto;
  text-align: initial;
  color: #1e143c;
  font-size: 1.5vw;
}

.el-collapse {
  margin: 5% 0;
}

.collapse_title {
  font-size: 2vw;
  color: #1e143c;
  font-weight: bolder;
}

.div__respuesta {
  padding-top: 1%;
  font-size: 1.5vw;
  color: #1e143c;
}

.el-collapse-item.is-active + div {
  background-color: #1e143c !important;
  color: white;
}

@media all and (max-width: 1000px) and (min-width: 600px) {
  .el-collapse-item >>> .el-collapse-item__header {
    height: 6vw;
  }

  .centered {
    font-size: 4vw;
  }

  h1 {
    font-size: 4vw;
    margin-top: 6%;
  }

  p {
    font-size: 2vw;
  }

  .collapse_title {
    font-size: 3vw;
    color: #1e143c;
    font-weight: bolder;
  }

  .div__respuesta {
    font-size: 2vw;
    color: #1e143c;
  }
}

@media all and (max-width: 600px) {
  .questions {
    width: 90%;
    margin: 3% auto;
  }

  .container__img {
    height: 40vw;
  }

  .el-collapse-item >>> .el-collapse-item__header {
    height: 10vw;
  }

  .centered {
    font-size: 8vw;
  }

  h1 {
    font-size: 7vw;
    margin-top: 6%;
    text-align: center;
  }

  p {
    font-size: 4.5vw;
    margin: 8% 0;
  }

  .collapse_title {
    font-size: 4.2vw;
    color: #1e143c;
    font-weight: bolder;
  }

  .div__respuesta {
    font-size: 4vw;
    color: #1e143c;
  }
}
</style>
